<template>
  <b-overlay
    class="tw-min-h-56"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <main>
      <section
        class="tw-flex tw-bg-white tw-rounded-xl tw-py-5 tw-px-10 tw-shadow-md tw-mt-5 tw-gap-10 tw-items-center"
      >
        <b-img
          alt="event-photo"
          :src="data.cover"
          width="200px"
          height="200px"
        ></b-img>
        <div class="tw-flex tw-flex-col tw-gap-1">
          <div>
            <h1 class="text-primary tw-m-0 tw-font-bold tw-text-[20px]">
              {{ data.name }}
            </h1>
            <div class="tw-flex tw-gap-2 tw-items-center">
              <p class="tw-text-sm tw-text-gray-400 tw-font-light">
                Jumlah Produk
                <span>({{ data.bundles.length }})</span>
              </p>
              <p>•</p>
              <p class="tw-text-sm tw-text-gray-400">
                Dibuat pada:
                <span>{{ formatDate(data.created_at) }}</span>
              </p>
            </div>
          </div>

          <p class="tw-break-all tw-font-semibold">{{ data.description }}</p>

          <div>
            <h3 class="text-primary tw-text-base tw-font-semibold">
              List Produk
            </h3>

            <div
              v-if="!!data.bundles.length"
              class="tw-flex tw-gap-3 tw-items-center"
            >
              <p
                class="tw-font-light"
                v-for="(item, index) in data.bundles"
                :key="index"
              >
                <span v-if="index !== 0" class="tw-mr-2">•</span>
                {{ item.name }}
              </p>

              <!-- <b-link
                :to="{ name: 'admin/manajemen-try-out/premium/campuran/to-mapel/to-soal', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
                >{{ data.item.fullName }}</b-link
              > -->
            </div>

            <p v-else class="tw-text-gray-300 tw-font-light">
              {{ "(Produk belum tersedia)" }}
            </p>
          </div>
        </div>
      </section>
    </main>
  </b-overlay>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api'

// Toast
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// API
import repository from "@repofactory";
const repoEvents = repository.get("EventRepository")

// Routing
import { useRouter } from "@core/utils/utils";

// Utils
import { formatDate, rupiah } from "@core/utils/utils";

// Bootstrap
import {
  BOverlay,
  BImg,
  BLink
} from "bootstrap-vue";


export default defineComponent({
    components: {
        BOverlay,
        BImg,
        BLink
    },
    setup() {
        const toast = useToast();
        const { route } = useRouter();

        const data = ref([])
        const isLoading = ref(false)

        const showToast = (title, text, variant, icon = "BellIcon") => {
            toast({
                component: ToastificationContent,
                props: {
                title,
                icon,
                text,
                variant,
                },
            });
        };

        const fetchData = async () => {
            try {
                isLoading.value = true;
                const res = await repoEvents.getDetail(route.value.params.id)
                data.value = res.data.data;

                isLoading.value = false;
            } catch (e) {
                isLoading.value = false;
                if (e.response.data.message) {
                showToast(
                    "Error",
                    e.response.data.message,
                    "danger",
                    "AlertTriangleIcon"
                );
                } else {
                showToast(
                    "Error",
                    "Error Fetching data event detail",
                    "danger",
                    "AlertTriangleIcon"
                );
                }

                isLoading.value = false;
            }
        };

        onMounted(() => {
            fetchData()
        })

        return {
            data,
            isLoading,

            // method
            formatDate,
        }
    },
})
</script>
